.margin_left_10 {
    margin-left: 10px;
}

.margin_right_10 {
    margin-right: 10px;
}

@media (max-width: 767px) {

    .hidden-xs {
        display: none !important;
    }
}