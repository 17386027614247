.imgs {
    display: flex;
    flex-direction: column;
}

.headerProductImport {
    border-bottom: 1px solid black;
    padding: 5px;
}

.ProductImportRow {
    border: 1px solid black;
    margin-bottom: 10px;
    border-radius: 5px;
}

.right {
    border-right: 1px solid black;
}

.page {
    margin-top: 100px;
}

.read-or-hide{
    color: blue;
    cursor: pointer;
}

.hr-or:before {
    background: #fff;
    content: 'OU';
    padding: 0 4px;
    position: relative;
    top: -13px;
}